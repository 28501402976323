import React, { useContext, useState } from 'react';
import { Button, Form, Input } from 'antd';
import { FieldType } from './types';
import './styles.scss'
import { useIntl } from 'react-intl';
import { Navigate, useNavigate } from 'react-router';
import { UserContext } from '../../Contexts/UserContext/UsersContext';
import { useLogin } from '../../Services/useLogin';
import { useForm } from 'antd/es/form/Form';


const Login: React.FC = () => {
    
    const navigate = useNavigate();
    const [form_login] = useForm()
    const[loadingBtn, setLoadingBtn] = useState(false)

    const {accesToken, setAccesToken,  getProfile} = useContext(UserContext);
    const { loginRequest } = useLogin()

    const intl = useIntl();

    const callback=()=>{
        setLoadingBtn(false)
    }

    const onFinish = async(values: {email: string, password: string}) => { 
       const data = await loginRequest(callback, values)        
        if(data?.status === 200){
            setAccesToken(data.data.access_token)
            getProfile()
            navigate('/dashboard')
        }       
    };

    
      
    const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
    };

    return( 
        <div className='login_container'>
            {/* <div className='login_row_logo'>
                <img src={logo}/>
            </div> */}
           <div className='login_row_form'>
                <Form
                    layout="vertical"
                    name="form_login"
                    form={form_login}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{ maxWidth: 600 }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    
                >
                    <Form.Item
                        name="email"
                        rules={[{ 
                            required: true, 
                            message: 'Campo obligatorio'//intl.formatMessage(messages.rule) 
                        }]}
                    >
                        <Input 
                            placeholder='Email'//{intl.formatMessage(messages.placeholderUser)}
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ 
                            required: true, 
                            message: 'Campo obligatorio'// intl.formatMessage(messages.rule)  
                        }]}
                    >
                        <Input.Password 
                            placeholder='Contraseña'//{intl.formatMessage(messages.placeholderPassword)}
                        />
                    </Form.Item>
                    <Form.Item 
                    style={{
                        marginBottom: '0px'}}
                    >
                        <Button 
                            type="primary" 
                            htmlType="submit"
                            loading={loadingBtn}    
                        >
                            ingresar
                        </Button>
                    </Form.Item>
                </Form>
            </div>
           
            <div className='login_background'>
            
            </div>
        </div>
    )
};

export default Login;