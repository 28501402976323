import React, {
    createContext,
    useEffect,
    useState,
  } from 'react';
import { useNavigate  } from "react-router-dom";
import { useIntl } from 'react-intl';
import { axiosBackEndInstance } from '../../Axios'; 
import { useLocalStorageState } from '../../hooks/useLocalStorageState';
import { ProfileProps } from '../../Services/useGetProfile';
import { END_POINT_USER_PROFILE } from '../../Axios/ENDPOINTS';

  class VoidContext implements UserContext {
    get changeAccessPageForm(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setChangeAccesPageForm(): Function {
      throw new Error('Cannot consume context outside of provider');
    }
    get showAnimated(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setShowAnimated(): Function {
      throw new Error('Cannot consume context outside of provider');
    }
     get loading(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setLoading(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get showError(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setShowError(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get statusRequest():never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setStatusRequest(): Function {
      throw new Error('Cannot consume context outside of provider');
    }
    get accesToken():never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setAccesToken(): Function {
      throw new Error('Cannot consume context outside of provider');
    }
    get userData(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get unlog():never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setUnlog(): Function {
      throw new Error('Cannot consume context outside of provider');
    }
    get collapsed():never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setCollapsed(): Function {
      throw new Error('Cannot consume context outside of provider');
    }
    get getProfile(): Function {
      throw new Error('Cannot consume context outside of provider');
    }
  }

  interface UserContext {
    changeAccessPageForm: String,
    setChangeAccesPageForm: Function,
    loading: boolean,
    setLoading: Function,
    showError: string,
    setShowError: Function,
    statusRequest: number,
    setStatusRequest: Function,
    accesToken : string | undefined
    setAccesToken: Function,
    unlog: boolean,
    setUnlog: Function,
    userData: ProfileProps | undefined,
    collapsed: boolean,
    setCollapsed: Function,
    getProfile: Function
  }

  export interface UserData {
    firstName: string,
    lastName: string,
    userId: number,
  }

  interface propsWithChildren {
    children: React.ReactNode
  }

  export interface accesToken{
    access: string,
    refresh:string
  }
  
  export const UserContext = createContext<UserContext>(new VoidContext());
  export const UserContextProvider= ({ children }: propsWithChildren): JSX.Element => {

    const intl = useIntl();
    const navigate = useNavigate();

    const [changeAccessPageForm, setChangeAccesPageForm]= useState<string>('login')
    const [loading, setLoading]= useState<boolean>(false)
    const [showError, setShowError]= useState<string>('')
    const [statusRequest, setStatusRequest] = useState<number>(0)
    const [unlog, setUnlog] = useState(true)
    const [collapsed, setCollapsed] = useState(true);
    const [accesToken, setAccesToken] = useLocalStorageState<string | undefined>(
      'financial-Token', undefined
    ); 
    const [userData, setUserData] = useState<ProfileProps>()
    


    useEffect(()=>{
      if(!userData){
        getProfile()
      }      
    },[])

    const getProfile = async() =>{
      try {
        let data = await axiosBackEndInstance.get(END_POINT_USER_PROFILE)
        if(data && data.status == 200){
          setUserData(data.data)
        }        
      } catch (error) {
        console.log(error);
      } 
    }

    // tipos de movimientos
    // TYPE_CASH_DEPOSIT_ID = 1 Depósito cuenta corriente
    // TYPE_CASH_EXTRACTION_ID = 2 Extracción cuenta corriente
    // TYPE_BANK_DEPOSIT_ID = 3 Depósto bancario
    // TYPE_BANK_EXTRACTION_ID = 4 Extracción bancaria
    // TYPE_CHECKOUT_TRANSFER = 5 transferencia entre cajas
    // TYPE_ACCOUNT_TRANSFER = 6 Transferencia entre cuentas
    // TYPE_CURRENCY_EXCHANGE = 7 compra venta
    // TYPE_BANK_TRANSFER_COMMISSION = 8 

 
    return (
      <UserContext.Provider value={{
        changeAccessPageForm, 
        setChangeAccesPageForm,
        showError,
        setShowError,
        loading,
        setLoading,
        setStatusRequest,
        statusRequest,
        accesToken, 
        setAccesToken,
        unlog, 
        setUnlog,
        userData,
        collapsed, 
        setCollapsed,
        getProfile
      }}>{children}</UserContext.Provider>
    );
  };



  