import React, { useContext, useEffect, useState } from 'react';
import './styles.scss'
import {  Button, Col, Modal, Row, Select, Table, Form, Tooltip, Flex } from 'antd';
import {
    DeleteOutlined,
    ExclamationCircleOutlined,
    UserSwitchOutlined,
    WarningOutlined
  } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import  { ColumnsType } from 'antd/es/table';
import { SpinerHorizontal } from '../../SpinerHorizontal/SpinerHorizontal';
import { UsersSectionProps, ValuesForm } from './types';
import { Transaction } from '../../../Services/useGetMovements';
import { useGetAllClients } from '../../../Services/useGetAllClients';
import { useGetCurrency } from '../../../Services/useGetCurrency';
import DepositExtractionForm from '../../Forms/DepositExtractionForm';
import PucrchaseSaleCurrencyForm from '../../Forms/PucrchaseSaleCurrencyForm';
import { useOperations } from '../../../Services/useOperations';
import TransfersBetweenAccounts from '../../Forms/TransfersBetweenAccounts';
import { RenderApproved } from '../../RenderApproved';
import {  ResponseAccounts, useAccounts } from '../../../Services/useAccounts';
import moment from "moment";
import { StylesContext } from '../../../Contexts/UserContext/StylesContext';
import { VariablesContext } from '../../../Contexts/UserContext/VariablesContext';
import { DataType, MovementAccountContext } from '../../../Contexts/UserContext/MovementAcountContext';



const MovementsAccount: React.FC<UsersSectionProps> = () => {
 
  const { data: clients } = useGetAllClients()
  const { data: currencies } = useGetCurrency()
  const { Option } = Select;
  const { getOperationAmount, deleteOperation } = useOperations()
  const { getAccountsCurrencyAndCustomer } = useAccounts()    
  const { adjustedTableHeigthScroll } = useContext(StylesContext) 
  const { setShowForms, showForms } = useContext(VariablesContext)
  const {
    dataTable, 
    setDataTable,
    mutate, 
    setMutate,
    showingAccount, 
    setShowingAccount,
    balance, 
    setBalance
  } = useContext(MovementAccountContext)

  const [form_deposit_extraction] = useForm()
  const [form_transferers] = useForm()
  const [form_buy_and_sell] = useForm()
  const [form_movements_amount] = useForm()

  const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [rowSelect, setRowSelect] =useState<DataType | undefined>()    
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)

   const errorCustomers = 'NO HAY CLIENTES CREADOS PARA HACER UNA BÚSQUEDA. CREE UNO EN: ADMINISTRACIÓN => CLIENTES.'
    const errorMoney = 'NO HAY MONEDAS CREADAS PARA HACER UNA BÚSQUEDA. CREE UNA EN: ADMINISTRACIÓN => MOEDAS.'

  const [currencySelected, setCurrencySelected] = useState<{
    id: number,
    name: string,
    sign: string
  }>()

  const mandatory = [
    {
      required: true,
      message:'Este campo es obligatorio'
    }
  ] 

  useEffect(()=>{
    if(mutate){
      handleSearch(false)
      setMutate(false)
    }
  },[mutate])

  useEffect(()=>{
    if(showingAccount){
      form_movements_amount.setFieldsValue({
        customer:{
            value: showingAccount.user,
            label: showingAccount.user,
            key: showingAccount.user_id
          },
          currency:{
            value: showingAccount.sign,
            label:  showingAccount.sign,
            key: showingAccount.sign_id
          }
      })
    }
  },[])

  const getTrasnferData = (list: Transaction)=>{
    let client_balance = 0;
    let debit  
    let credit 
    let formData: { customer: ValuesForm, currency: ValuesForm } = form_movements_amount.getFieldsValue();
    let customer = formData.customer;

    if (customer) {
      if (list.destination_account?.customer && (customer?.key == list.destination_account.customer.id) ) {
        client_balance = list.destination_account_balance;
        credit = list.credit
      } else if (list.origin_account?.customer && (customer?.key == list.origin_account.customer.id)) {
        client_balance = list.origin_account_balance;
        debit = list.debit
      }
    }

    return{
      client_balance,
      debit,
      credit
    }
  }

  const getBySData = (list: Transaction)=>{    
    let client_balance = 0;
    let debit 
    let credit
    let formData: { customer: ValuesForm, currency: ValuesForm } = form_movements_amount.getFieldsValue();
    let currency = formData.currency;

    if (currency) {
      if (list.destination_account?.currency && (list.destination_account.currency.id == currency?.key)   ) {
        client_balance = list.destination_account_balance;
        credit = list.credit
      } else if (list.origin_account?.currency && (list.origin_account.currency.id == currency?.key)  ) {
        client_balance = list.origin_account_balance;
        debit = list.debit
      }
    }

    return{
      client_balance,
      debit,
      credit
    }
  }

  const selectedAccountDetails = async (list: Transaction) => {
    let accountData = {
      debit: '',
      credit: '',
      balance: ''
    }; 

    switch(list.type.id){
      case 1: // Depósito efectivo
      case 3: // Depósito bancaria 
        accountData = {
          debit: '',
          credit: new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(list.credit),
          balance: !list.approved ? '' : new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(list.destination_account_balance)          
        }
        break;
      case 2: // Extracción efectivo        
      case 4: // Extracción bancaria    
          accountData = {
            debit: new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(list.debit),
            credit: '',
            balance: !list.approved ? '' : new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(list.origin_account_balance)
          };       
        break;
      case 6: // Trasnferencia entre cuentas
        //Si es trasnferencia busco la coincidencia del cliente seleccionado con la cuenta
        let transferData =  getTrasnferData(list)
        if(transferData){
          accountData = {
            debit: transferData.debit ? new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(transferData.debit) : '',
            credit: transferData.credit ? new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(transferData.credit) : '',
            balance: !list.approved ? '' : new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(transferData.client_balance)
          };  
        }
        
        break;
      case 7: //compra venta moneda
        //Si es trasnferencia busco la coincidencia de la moneda seleccionada con la cuenta
        let BySData =  getBySData(list)
        if(BySData){
          accountData = {
            debit: BySData.debit ? new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(BySData.debit) : '',
            credit: BySData.credit ? new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(BySData.credit) : '',
            balance: !list.approved ? '' : new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(BySData.client_balance)
          };  
        }      
        break;
      }  
    return accountData;
  };


  const rowData = async (data: Transaction[]) => {
    if (!data) return; 
    const promises = data.map(async (transaction) => {
      const accountData = await selectedAccountDetails(transaction);
      
      return {
        id: transaction.id.toString(),
        operation_type_id: transaction.type.id.toString(),
        operation_type_name: transaction.type.name,       
        credit: accountData.credit,        
        debit: accountData.debit,
        origin_account_id: transaction.origin_account?.id?.toString() || '',
        origin_account_descriptrion: transaction.origin_account?.description || '',
        origin_account_currency_name: transaction.origin_account?.currency?.name || '',
        origin_account_currency_sign: transaction.origin_account?.currency?.sign || '', 
        origin_account_currency_id: transaction.origin_account?.currency?.id?.toString() || '',
        origin_balance: transaction.origin_account?.balance?.toString() || '',           
        destination_account_id: transaction.destination_account?.id?.toString() || '',
        destination_account_descriptrion: transaction.destination_account?.description || '',
        destination_account_currency_id: transaction.destination_account?.currency?.id?.toString() || '',
        balance: accountData.balance,
        destination_account_currency_name: transaction.destination_account?.currency?.name || '',
        destination_account_currency_sign: transaction.destination_account?.currency?.sign || '',   
        destination_balance: transaction.destination_account?.balance?.toString() || '',                                    
        customer_id: transaction.customer?.id?.toString() || '',
        customer_first_name: transaction.customer?.first_name || '',
        customer_last_name: transaction.customer?.last_name || '',            
        user_id: transaction.user?.id?.toString() || '',
        user_name: transaction.user?.first_name || '',
        user_last_name: transaction.user?.last_name || '',          
        description: transaction.description || '',
        currency_sale: transaction.currency_sale ? 'true' : 'false',
        approved: transaction.approved ? 'true' : 'false',
        is_deleting_operation: transaction.is_deleting_operation ? 'true' : 'false',
        deleted: transaction.deleted ? 'true' : 'false',
        date: moment(transaction.updated_at).format('DD-MM-YY  HH:mm:ss') || moment(transaction.created_at).format('DD-MM-YYYY  HH:mm:ss'),        

        destination_checkout_name: transaction.destination_checkout?.name || '',
        destination_checkout_id: transaction.destination_checkout?.id?.toString() || '',         
        origin_checkout_name: transaction.origin_checkout?.name || '',
        origin_checkout_id: transaction.origin_checkout?.id.toString() || '',
        credit_number: transaction.credit?.toString() || '',
        debit_number: transaction.debit?.toString() || '',

        destination_checkout_sign: transaction.destination_checkout?.currency.sign || '', 
        origin_checkout_sign: transaction.origin_checkout?.currency?.sign || '',
        destination_checkout_currency_id: transaction.destination_checkout?.currency?.id?.toString() || '', 
        origin_checkout_currency_id: transaction.origin_checkout?.currency?.id?.toString() || '',

        destination_account_customer_name: transaction.destination_account?.customer?.first_name || '',
        destination_account_customer_last_name: transaction.destination_account?.customer?.last_name || '',
        destination_account_customer_id: transaction.destination_account?.customer?.id?.toString() || '',

        origin_account_customer_name: transaction.origin_account?.customer?.first_name || '',
        origin_account_customer_last_name: transaction.origin_account?.customer?.last_name || '',
        origin_account_customer_id: transaction.origin_account?.customer?.id?.toString() || '',
        //commission: transaction.

      };
    });
  
    try {
      const filterData = await Promise.all(promises);
      console.log(filterData)
      setDataTable([...filterData]);
    } catch (error) {
      console.error('Error processing row data:', error);
    }
  };

  const callbackEliminate = () =>{
      setLoadingTableData(false)
      setRowSelect(undefined)        
      setShowConfirmDelete(false)
  }

  const handleRenderer = (text: string, record: DataType) =>{
    return (
      <div className='currency_action_action_render'>
          {
            record.deleted == 'true' || record.is_deleting_operation == 'true' 
              ? <></>
              :  <DeleteOutlined 
              onClick={()=>{
                  setRowSelect(record)
                  setShowConfirmDelete(true)
              }}
              style={{marginLeft:'30px', color: '#37bde0'}} /> 
          }          
      </div>  
    )      
  }
    
  const ModalConfirmDelete = React.memo( () => {
    return(
        <Modal
            className='movements_section_icon'
            title="Eliminar"
            open={showConfirmDelete}
            onOk={async()=>{
                if(rowSelect && rowSelect.id){
                  const data = await deleteOperation(callbackEliminate, +rowSelect.id)
                  if(data){
                    handleSearch(false)
                  }
                }}}
            onCancel={()=>setShowConfirmDelete(false)}
            okText="Eliminar"
            cancelText="Cancelar"
        >
            <p>¿Esta seguro de que desea eliminar operación N°{rowSelect?.id}?</p>
        </Modal>
    )
  })

  const renderOperationType =(text: string, record: DataType)=>{
    let color = ''
    let showIcon = false
    let txt = ''

    if(record.approved == 'false'){
      showIcon = true
      txt = 'Pendiente de aprobación'
      color = 'orange'
    }
    if(record.deleted == 'true'){
      showIcon = true
      txt = 'Operación eliminada'
      color = 'red'
    }
    if(record.is_deleting_operation == 'true'){
      showIcon = true
      txt = 'Reverso por eliminación de operación'
      color =  'rgba(128, 128, 128, 0.671)'
    }

    return(
      <div  className={
        record.deleted == 'true' 
          ? 'class_deleted' : record.is_deleting_operation == 'true' 
          ? 'class_is_deleting_operation' : 'class_false'
        }>   
        {record.operation_type_name} {' '} {showIcon  && 
        <Tooltip
          title={txt}
        >
          <ExclamationCircleOutlined
            style={{color: color, fontSize: '12px'}}
          />
        </Tooltip>
      
      }
      </div>
    )}

  const handleRender = (text: string, record:DataType)=>{ 

    return( 
      <div className={
        record.deleted == 'true' 
          ? 'class_deleted' : record.is_deleting_operation == 'true' 
          ? 'class_is_deleting_operation' : 'class_false'
        } >{text}
      </div>
    )     
  } 
  
  const columns: ColumnsType<DataType> = [
    {
      title: 'N° ',
      dataIndex: 'id',
      width: 70,
      key: '1',
      className:'cursor-pointer',
      render: handleRender,     
    },
    {
      title: 'Tipo de operación',
      dataIndex: 'operation_type_name',
      render: renderOperationType,
      width: 310,
      key: '2',
      className:'cursor-pointer',
    
    },    
    {
      title: 'Fecha y hora',
      dataIndex: 'date',
      width: 140,
      key: '3',
      className:'cursor-pointer',   
      render:(text: string, record:DataType)=>
        <div className={
          record.deleted == 'true' 
            ? 'class_deleted' : record.is_deleting_operation == 'true' 
            ? 'class_is_deleting_operation' : 'class_false'
          } >{text}
        </div>,  
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
      render:(text: string, record:DataType)=>
        <div className={
          record.deleted == 'true' 
            ? 'class_deleted' : record.is_deleting_operation == 'true' 
            ? 'class_is_deleting_operation' : 'class_false'
          } >{text}
        </div>,
      key: '4',
      className:'cursor-pointer',  
      ellipsis: true   
    },
    {
      title: 'Débito',
      dataIndex: 'debit',
      key: '5',
      width: 110,
      render:(text: string, record:DataType)=>
        <div className={
          record.deleted == 'true' 
            ? 'class_deleted' : record.is_deleting_operation == 'true' 
            ? 'class_is_deleting_operation' : 'class_false'
          } >{text}
        </div>,
      className:'cursor-pointer'
    },
    {
      title: 'Crédito',
      dataIndex: 'credit',
      key: '6',      
      width: 110,
      render:(text: string, record:DataType)=>
        <div className={
          record.deleted == 'true' 
            ? 'class_deleted' : record.is_deleting_operation == 'true' 
            ? 'class_is_deleting_operation' : 'class_false'
          } >{text}
        </div>,      
      className:'cursor-pointer'
    },    
    {
      title: 'Saldo',
      dataIndex: 'balance',
      key: '7',   
      width: 110,
      render:(text: string, record:DataType)=>
        <div className={
          record.deleted == 'true' 
            ? 'class_deleted' : record.is_deleting_operation == 'true' 
            ? 'class_is_deleting_operation' : 'class_false'
          } >{text}
        </div>,
      className:'cursor-pointer',
    },
    {
      title: 'Aprobado',
      dataIndex: 'approved',
      key: '8',
      width: 100,
      render: (text: string, record: DataType)=> <RenderApproved text={text} record={record} setDataTable={setDataTable} dataTable={dataTable} handleSearch ={handleSearch}/>,
      className:'cursor-pointer',      
     
    },
    {
      title: 'Eliminar',
      dataIndex: 'id',
      key: '9',        
      render:  handleRenderer,
      width: 100,  
      onCell: (record, rowIndex) => {
        return {
          onClick: () => {
            onClickRow(record)
          }
        };
      }    
    }, 
  ];

  const onClickRow = (record: DataType) =>{         
    if(record &&  record.approved == 'false'){
      setRowSelect(record);
    }  
  }   
  
  const handleSearch = async(loading?: boolean ) =>{
      if(loading){
        setLoadingTableData(true)
      }      
      let formData:{customer: ValuesForm, currency: ValuesForm}  = form_movements_amount.getFieldsValue()
      let currency =  formData.currency   
      let customer =  formData.customer      
      checkAccountBalance()
      let values ={
        customer_id: customer.key,
        currency_id: currency.key, 
        checkout_id: undefined,   
        start_date: undefined,
        end_date: undefined,
        operation_type: undefined
      }
      const data = await getOperationAmount(callbackEliminate, values )
      if(data){
        setShowingAccount({
          user_id: customer.key,
          user: customer.label,
          sign: currency.label,
          sign_id: currency.key
        })
        rowData(data)
      }   
  }

  const checkAccountBalance =  React.useCallback(async()=>{             
    let formData:{customer: ValuesForm, currency: ValuesForm}  = form_movements_amount.getFieldsValue()
    let currency_id = formData && formData.currency &&  formData.currency.key   
    let customer_id = formData && formData.customer &&  formData.customer.key   
    if(customer_id && currency_id){
        const data = await getAccountsCurrencyAndCustomer(customer_id, currency_id)
        if(data && data){
            setBalance((prevData: ResponseAccounts) => {
                if (JSON.stringify(prevData) !== JSON.stringify(data)) {
                    return data;
                }
                return prevData; // No actualizar si son iguales
            });
        }
    }         
  },[form_movements_amount, getAccountsCurrencyAndCustomer])

  const onChangeCurrency =React.useCallback( (e: {label: string, key: number}, value: any) =>{
    setCurrencySelected({
      name: e.label,
      id: +e.key,
      sign: value.sign
    })
  },[])

  return( 
    <>
      <ModalConfirmDelete/>
      { showForms.deposit_extraction &&
        <DepositExtractionForm
          form={form_deposit_extraction}
          showForms={showForms}
          setShowForms={setShowForms}
          rowSelect={rowSelect}
          setRowSelect={setRowSelect}
        />
      }
      { showForms.transferer && 
        <TransfersBetweenAccounts
          form={form_transferers}
          showForms={showForms}
          setShowForms={setShowForms}
          rowSelect={rowSelect}
          setRowSelect={setRowSelect}
        />
      }    
      { showForms.buy_and_sell &&
        <PucrchaseSaleCurrencyForm
          form_buy_and_sell={form_buy_and_sell}
          showForms={showForms}
          setShowForms={setShowForms}
          rowSelect={rowSelect}
          setRowSelect={setRowSelect}
        />
      } 
      <div className='movements_section movements_section_container'>                                  
          <Row
            className='global_title_section'             
          >
            <Col
              style={{
                display: 'flex',
                alignItems: ' center'
              }}
            >
              <h3> <UserSwitchOutlined style={{fontSize: 18, marginRight: '10px', color: '#37bde0'}}/>MOVIMIENTOS DE CUENTAS  </h3>   
              {
                showingAccount && 
                  <h3
                    style={{ margin: '7px 0px 0px 10px', padding: '0px', color: '#bdbdbd'}}
                  >/ {showingAccount?.user}  ({showingAccount?.sign})</h3> 
              }
            </Col>
            <Col
              style={{display: 'flex'}}
            >
              <div 
                style={{
                  display: 'flex', 
                  alignItems: 'center', 
                  backgroundColor: balance && balance.balance < 0 ? 'red' : '#37bee042', 
                  borderBottom:`2px solid ${balance && balance.balance < 0 ? '#ff7c7c' : '#37bde0'}`, 
                  paddingLeft: '15px', 
                  marginRight: '30px',
                  borderRadius: '5px',
                  height: '32px',
                  width: 'auto',
                  minWidth: '210px'
                }}>
                <h3 
                  style={{
                    margin: '0px',
                    
                  }}
                >
                  Saldo: {currencies && balance && currencies.filter(x => x.id === balance?.currency_id)[0]?.sign }  {' '}                                    
                </h3>
                <h3
                  style={{
                    marginLeft: '5px',
                    color: 'white'
                  }}
                >
                  {balance? new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(balance.balance) : ''}
                </h3>
              </div>  
              <div 
                style={{
                  display: 'flex', 
                  alignItems: 'center', 
                  backgroundColor: balance && balance.future_balance > balance.balance ? '#ffff00a1': balance &&  balance.future_balance < balance.balance ? '#ff00009a' :'#80808098', 
                  borderBottom: `2px solid ${balance && balance.future_balance > balance.balance ? 'yellow' : balance && balance.future_balance < balance.balance ? 'red' : 'gray'}`,
                  paddingLeft: '15px', 
                  marginRight: '30px',
                  borderRadius: '5px',
                  height: '32px',
                  width: 'auto',
                  minWidth: '220px'
                }}>
                <h3 
                  style={{
                    margin: '0px',
                    
                  }}
                >
                  S. Futuro: {currencies && balance && currencies.filter(x => x.id === balance?.currency_id)[0]?.sign }  {' '}                                    
                </h3>
                <h3
                  style={{
                    marginLeft: '5px',
                    color: 'white'
                  }}
                >
                  {balance? new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(balance.future_balance) : ''}
                </h3>
              </div> 
            </Col>                       
          </Row>
          <Row 
            className='global_button_box'
            style={{
              display:'flex',
              flexDirection: 'column'
              // justifyContent: 'space-between',
              // alignItems:'center'
            }}
          >
            <Col >
              <Form
                layout='inline'
                onFinish={()=>handleSearch(true)}
                id='form_movements_amount' 
                form={form_movements_amount}
              >
                <Form.Item
                    name='customer'
                    rules={mandatory}
                >
                  <Select                           
                    labelInValue  
                    loading={!clients }
                    placeholder={!clients ? 'Cargando campos...' : 'Cliente'}      
                    style={{minWidth: '200px'}}   
                    showSearch                                                  
                  >
                      {clients && clients.map((item) => (
                          <Option value={item.first_name + ' ' + item.last_name} key={item.id} >
                            {item.first_name + ' ' + item.last_name}
                          </Option>
                      ))} 
                  </Select>
                </Form.Item>
                <Form.Item
                    name='currency'
                    rules={mandatory}
                >
                  <Select                           
                      labelInValue  
                      loading={!currencies || currencies.length === 0}
                      placeholder={!currencies || currencies.length === 0 ? 'Cargando monedas...' : 'Moneda'}                       
                      style={{minWidth: '105px'}}
                      onChange={(e, value) => onChangeCurrency(e, value)}
                      showSearch
                  >
                      {currencies && currencies && currencies.map((item) => (
                          <Option value={item.name} key={item.id} sign={item.sign} >
                            {item.name}
                          </Option>
                      ))} 
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Button
                    type='primary'
                    className='movements_section_btn'
                    htmlType='submit'
                    loading={loadingBtn}
                  >
                    Buscar
                  </Button>
                </Form.Item>
                <Tooltip
                  title= 'Seleccione un cliente y la moneda para buscar los movimientos correspondientes a esa cuenta.'
                  className='cursor-pointer'
                >
                  <ExclamationCircleOutlined style={{color: 'orange'}}/>
                </Tooltip>
              </Form>                               
            </Col>          
            {
              clients && clients.length == 0 &&
              <span style={{color: '#ff6161', fontFamily: 'monospace'}}>
                <WarningOutlined style={{marginRight: '10px', marginTop: '10px', color: '#71e3ff '}}/>{errorCustomers}
              </span>
            } 
            { 
              currencies && currencies.length == 0 &&
              <span style={{color: '#ff6161', fontFamily: 'monospace'}}>
                <WarningOutlined style={{marginRight: '10px', marginTop: '10px',color: '#71e3ff '}}/>{errorMoney}
              </span>
            }                                                                     
          </Row>
          <Row 
            style={{
                display:'flex',
                justifyContent: 'center',
                width: '100%',
                height: '85%',
                padding: '0px 30px',                        
                border: '1px solid   #645fca6c'
            }}
          >
            { loadingTableData && <SpinerHorizontal/>}
            { !loadingTableData && showingAccount
                ?
                  <Table 
                    style={{
                        width: '100%',
                        marginTop: '1%'
                    }}
                    columns={columns} 
                    dataSource={dataTable}  
                    scroll={{ y: adjustedTableHeigthScroll }}                                   
                    pagination={{
                        pageSize: 50,
                        showSizeChanger: false,                                      
                    }}
                    onRow={(record, ) => ({                    
                      onClick: (event) => {
                        console.log(event)
                        if(record.approved == 'false'){
                          // Obtén las celdas correspondientes a las columnas "Aprobado" y "Eliminar"
                          const approvedCell = event.currentTarget.querySelector('td:nth-child(8)');
                          const deleteCell = event.currentTarget.querySelector('td:nth-child(9)');
                          
                          // Verifica si el clic ocurrió en las celdas "Aprobado" o "Eliminar"
                          if ((approvedCell && approvedCell.contains(event.target)) || (deleteCell && deleteCell.contains(event.target))) {
                            // No ejecutar la función si el clic ocurrió en "Aprobado" o "Eliminar"
                            return;
                          }
                          onClickRow(record)
                          let deposit_state = false
                          let transferer_state = false
                          let buy_sell_state = false
                          switch(record.operation_type_id){
                            //5 trasnferencia entre cajas
                            case '1':
                            case '3':
                            case '2': // Extracción efectivo
                            case '4': // Extracción bancaria
                              deposit_state = true
                              break;
                            case '6': //treansferencia entre cuentas clientes
                              transferer_state = true
                              break;
                            case '7': //compra venta moneda
                              buy_sell_state = true
  
                          }
                          setShowForms({
                            deposit_extraction: deposit_state,
                            transferer: transferer_state,
                            buy_and_sell: buy_sell_state
                          })
                        }                        
                      },
                    })}
                  /> 
                : 
                 <>
                 </>
            }                             
          </Row>             
      </div>
    </>
  )
};

export default MovementsAccount;

 