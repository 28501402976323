import { Button } from 'antd'
import './styles.scss'
import { MovementsBoxProps } from './types'

export const MovementsBox: React.FC<MovementsBoxProps> = ({
    setShowForms
}) =>{

    const btnsData = [
        {
            label: 'Depósito / Extracción',
            onClick: ()=>{
                setShowForms({
                    deposit_extraction: true,
                    transferer: false,
                    buy_and_sell: false,
                    transfers_between_cashs: false
                })
            }
        },
        {
            label: 'Transferencia entre cuentas',
            onClick: ()=>{
                setShowForms({
                    deposit_extraction: false,
                    transferer: true,
                    buy_and_sell: false,
                    transfers_between_cashs: false
                })
            }
        },
        {
            label: 'Compra / Venta',
            onClick: ()=>{
                setShowForms({
                    deposit_extraction: false,
                    transferer: false,
                    buy_and_sell: true,
                    transfers_between_cashs: false
                })
            }
        },
        {
            label: 'Trasnferencias entre caja',
            onClick: ()=>{
                setShowForms({
                    deposit_extraction: false,
                    transferer: false,
                    buy_and_sell: false,
                    transfers_between_cashs: true
                })
            }
        }
    ]

    return(
        <div className='movements-box'>
            <p>FORMULARIOS</p>
            <div 
                className='movements-box_container'  
                style={{
                    marginTop:'50px'
                }}          
            >  
            
            {btnsData.map((btn)=>
                <Button
                    type='primary'
                    className='movements_section_btn'
                    style={{
                        marginBottom: '10px',
                        width: '85%'
                    }}
                    onClick={btn.onClick}
                >
                    {btn.label}
                </Button>
            )}                                              
            </div>   
        </div>
         
    )
}