import { useContext, useEffect } from 'react';
import useSWR, { KeyedMutator } from "swr";

import { useAuthSWR } from './useAuthSWR';
import { END_POINT_USER_GET_ALL } from '../Axios/ENDPOINTS';

export interface UsersProps {
  id: number,
  email: string,
  first_name: string,
  last_name: string, 
  role: string
}


export interface ResponseGetUsers{
  count: number,
  current_page: number,
  next: string,
  previous: string
  results: UsersProps[]  
}

export const useGetUsers = () => {
    
    const {data, error, mutate }:{
      data: UsersProps[], 
      error: any,
      mutate: KeyedMutator<any>
    } = useAuthSWR([END_POINT_USER_GET_ALL])    
  
    return {
      data,
      mutate,
      error
    };
};