import { useState } from "react"
import {
    CheckOutlined,
  } from '@ant-design/icons';
import { useOperations } from "../../Services/useOperations";
import './styles.scss'
import { DataType } from "../../Contexts/UserContext/MovementAcountContext";

export const RenderApproved: React.FC<{
  text: string, 
  record: DataType, 
  setDataTable: Function,
  dataTable: DataType[]
  handleSearch: Function
}> = ({
    text, 
    record, 
    setDataTable, 
    dataTable, 
    handleSearch
  }) =>{

  const {confirmOperation} = useOperations()

  const [loadingApproved, setLoadingApproved] = useState(false)
  const handleApproved = async ()=>{
    setLoadingApproved(true)
    const status = await confirmOperation(+record.id, setLoadingApproved)
    if(status == 200 ){
      handleSearch(false)
    }
  }

  return(
    <>
      {
        text == 'true'
        ? <CheckOutlined style={{color: 'greenyellow', paddingLeft: '10px'}} />
        : <p
            onClick={handleApproved} 
            className={loadingApproved ? "loading_btn":"approve_btn"}
            //style={{color: loadingApproved ? 'grey' :'orange ', padding: '0px', margin: '0px', }}
          >
            { loadingApproved ? 'cargando...' : 'Aprobar' }
          </p>
      }    
    </>
  )
  }