import { axiosBackEndInstance } from '../Axios';
import { END_POINT_CUSTOMER } from '../Axios/ENDPOINTS';
import { notification } from 'antd';
import { useErrorHandling } from '../hooks/useErrorHandling';
import { useGetUsers } from './useGetUsers';
import { useGetAllClients } from './useGetAllClients';


export const useCustomers= () => {

    const {errorHandling} = useErrorHandling()
    const { mutate } = useGetAllClients()
     
     const createCustomers = async (
      callback: Function,
      values:{
        first_name: string,
        last_name: string,
        email: string,
        phone_number: string
      }
      
      ): Promise<any> => {      
    try {
      const  data = await axiosBackEndInstance.post(
        END_POINT_CUSTOMER, values
      )
      const status =  errorHandling(data).request.status
      if(status === 200){
        callback()
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Cliente creado con éxito', 
          placement: 'topRight'
      })
      }
    } catch (newError) {
      console.log(newError);
      callback()
      
    }
  };

  const editCustomers = async (
    callback: Function,
    id: number,
    values:{
      first_name: string,
      last_name: string,
      email: string,
      phone_number: string
    }
                            
  ): Promise<void> => {
              
    try {
      const data  = await axiosBackEndInstance.put(
        `${END_POINT_CUSTOMER}/${id}`, values
      );
      callback()   
      if(data.status === 200 || data.status === 201){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Cliente editado con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {    
      callback()
      let status = errorHandling(newError).request.status
      console.log('Status ' + status);
      console.log(newError);
    }
  };

  const deleteCustomers = async (
    callback: Function,
    id: number                            
  ): Promise<void> => {
      
    try {
      const data  = await axiosBackEndInstance.delete(
        `${END_POINT_CUSTOMER}/${id}`, 
      );
      callback()   
      if(data.status === 200){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Cliente eliminado con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {
      console.log(newError);
      callback()
      let status = errorHandling(newError).request.status
    }
  };

  return {
    editCustomers,
    createCustomers,
    deleteCustomers
  };
};
