import React, { useContext, useEffect, useState } from 'react';
import './styles.scss'
import {  Button, Col, Modal, Row, Select, Table, Form} from 'antd';
import {
    DeleteOutlined,    
  } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import  { ColumnsType } from 'antd/es/table';
import { SpinerHorizontal } from '../../SpinerHorizontal/SpinerHorizontal';
import { UsersSectionProps } from './types';
import { Transaction } from '../../../Services/useGetMovements';
import Filters from '../../Filters';
import { useGetAllClients } from '../../../Services/useGetAllClients';
import { DepositExtractionBox } from '../../Filters-Boxs/DepositExtractionBox';
import { useOperations } from '../../../Services/useOperations';
import { RenderApproved } from '../../RenderApproved';
import { StylesContext } from '../../../Contexts/UserContext/StylesContext';

export interface DataType {
  index: string,
  id: string;
  operation_type_id: string;
  operation_type_name: string;
  credit: string,
  debit: string,
  origin_account_id: string;
  origin_account_descriptrion: string;
  origin_currency_name: string;
  origin_currency_sign: string;  
  origin_balance: string;  
  destination_account_id: string;
  destination_account_descriptrion: string
  destination_currency_name: string;
  destination_currency_sign: string;  
  destination_balance: string;  
  balance: string;
  customer_id: string;
  customer_first_name: string;  
  customer_last_name: string;
  user_id: string ;
  user_name: string;
  user_last_name: string;
  description: string;
  currency_sale: string;
  approved: string  
}

const DepositExtraction: React.FC<UsersSectionProps> = () => {

  const {data: clients} = useGetAllClients()
  const { getDepositAndExtractions } = useOperations()
  const { Option } = Select;
  const [form_search_client] = useForm()
  const {adjustedTableHeigthScroll} = useContext(StylesContext) 

  const [form_deposit_extraction] = useForm()
  const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
  const [dataTable, setDataTable] = useState<DataType[]>([])
  const [rowSelect, setRowSelect] =useState<DataType | undefined>()    
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [showForms, setShowForms] = useState<{
    deposit_extraction: boolean,
    transferer: boolean,
    buy_and_sell: boolean,
  }>({
    deposit_extraction: false,
    transferer: false,
    buy_and_sell: false
  })

  const mandatory = [
    {
      required: true,
      message:'Este campo es obligatorio'
    }
  ] 

  const rowData = (info : Transaction[]) =>{
    let filterData: DataType[]= []
    info.map((data, index)=>{
      let balance = data.destination_account_balance ? data.destination_account_balance : data.origin_account_balance  
      filterData.push({
        index: index.toString(),
        id: data.id.toString(),
        operation_type_id: data.type.id.toString(),
        operation_type_name: data.type.name,
        credit: data.credit.toString(),
        debit: data.debit.toString(),
        origin_account_id: data.origin_account?.id?.toString() || '',
        origin_account_descriptrion: data.origin_account?.description || '',
        origin_currency_name: data.origin_account?.currency?.name || '',
        origin_currency_sign:  data.origin_account?.currency?.sign || '', 
        origin_balance:  data.origin_account?.balance?.toString() || '',           
        destination_account_id: data.destination_account?.id?.toString() || '',
        destination_account_descriptrion: data.destination_account?.description || '',
        balance: balance ?  new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(balance) : '',
        destination_currency_name: data.destination_account?.currency?.name || '',
        destination_currency_sign: data.destination_account?.currency?.sign || '',   
        destination_balance: data.destination_account?.balance?.toString() || '',                                    
        customer_id:  data.customer?.id?.toString() || '',
        customer_first_name:  data.customer?.first_name || '',
        customer_last_name:  data.customer?.last_name || '',            
        user_id:  data.user?.id?.toString() || '',
        user_name: data.user?.first_name || '',
        user_last_name:  data.user?.last_name || '',          
        description:  data.description || '',
        currency_sale: data.currency_sale ? data.currency_sale ?'true':'false' : 'null',
        approved: data.approved ? 'true' : 'false'
      }) 
    })   
    setDataTable([...filterData])
  }

  const handleSearch = async(valueForms: {client: {key: number}})=>{
    setLoadingTableData(true)
    const data = await getDepositAndExtractions(callback, valueForms.client.key)
    if(data){
      rowData(data)
    }
  }

  const callback = () =>{
      setLoadingTableData(false)
      setRowSelect(undefined)        
      setShowConfirmDelete(false)
  }

  const handleRenderer = (text: string, record: DataType) =>{
    return (
        <div className='currency_action_action_render'>
            <DeleteOutlined 
                onClick={()=>{
                    setRowSelect(record)
                    setShowConfirmDelete(true)
                }}
                style={{marginLeft:'30px', color: '#37bde0'}} /> 
        </div>  
    )      
  }
    
  const ModalConfirmDelete = () => {
    return(
        <Modal
            title="Eliminar"
            open={showConfirmDelete}
            onOk={()=>{
                if(rowSelect && rowSelect.id){
                    //deleteBranch(callback, rowSelect.id)
                }}}
            onCancel={()=>setShowConfirmDelete(false)}
            okText="Eliminar"
            cancelText="Cancelar"
        >
            <p>¿Estás seguro de que desea eliminar {rowSelect?.description}?</p>
        </Modal>
    )
  }

  const onClickRow = (record: DataType) =>{         
    setRowSelect(record)
    //setOpenForm(true) 
  }    
  

  const columns: ColumnsType<DataType> = [
    {
      title: 'Tipo de operación',
      dataIndex: 'operation_type_name',
      width: 350,
      key: '1',
      className:'cursor-pointer',
      onCell: (record, rowIndex) => {
        return {
          onClick: () => {
            onClickRow(record)
          }
        };
      }
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
      width: 350,
      key: '2',
      className:'cursor-pointer',    
    },
    {
      title: 'Débito',
      dataIndex: 'debit',
      key: '3',
      className:'cursor-pointer'
    },
    {
      title: 'Crédito',
      dataIndex: 'credit',
      key: '4',
      className:'cursor-pointer'
    },    
    {
      title: 'Saldo',
      dataIndex: 'balance',
      key: '5',
      className:'cursor-pointer',
    },
    {
      title: 'Aprobado',
      dataIndex: 'approved',
      key: '6',
     // render: (text: string, record: DataType)=> <RenderApproved text={text} record={record} setDataTable={setDataTable} dataTable={dataTable}/>,
      className:'cursor-pointer',      
      width: 100,
    },
    {
      title: 'Eliminar',
      dataIndex: 'id',
      key: '7',        
      render:  handleRenderer,
      width: 100,      
    }, 
  ];
    
const checkAccountBalance = ()=>{

}
  return( 
    <>
      <ModalConfirmDelete/>
      {/* { showForms.deposit_extraction &&
        <DepositExtractionForm
          form={form_deposit_extraction}
          showForms={showForms}
          setShowForms={setShowForms}
          setDataTable={setDataTable}
          dataTable={dataTable}
          checkAccountBalance={checkAccountBalance}
          balance={balance}
          />
      } */}
      {/* <Filters 
        components={<DepositExtractionBox  
        setShowForms={setShowForms}  
        he       
      />}/>       */}
      <div className='deposit_extraction_section deposit_extraction_container'>                                  
          <Row
            className='global_title_section' 
          >
              <h3>DEPÓSITO / EXTRACCIÓN</h3>                
          </Row>
          <Row 
            className='global_button_box'
          >
            <Col>
              <Form
                onFinish={handleSearch}
                id='form_search_client'
                layout='inline'
                form={form_search_client}
              >
                <Form.Item
                  name='client'          
                  rules={mandatory}
                >
                  <Select                           
                    labelInValue  
                    loading={!clients || clients.length === 0}
                    placeholder={!clients || clients.length === 0 ? 'Cargando campos...' : 'Cliente'}      
                    style={{minWidth: '200px'}}                               
                  >
                    {clients && clients.map((item) => (
                        <Option value={item.first_name + ' ' + item.last_name} key={item.id} >
                          {item.first_name + ' ' + item.last_name}
                        </Option>
                    ))} 
                  </Select>
                </Form.Item>           
                <Form.Item>
                  <Button
                    type='primary'
                    className='deposit_extraction_btn'
                    htmlType='submit'
                  >
                    Buscar
                  </Button>
                </Form.Item>
              </Form>                 
            </Col>                        
          </Row>
          <Row 
            style={{
                display:'flex',
                justifyContent: 'center',
                width: '100%',
                height: '80%',
                padding: '0px 30px',                        
                border: '1px solid   #645fca6c'
            }}
          >
            { loadingTableData && <SpinerHorizontal/>}
            { !loadingTableData &&
                dataTable.length > 0 
                ?
                  <Table 
                    style={{
                        width: '100%',
                        marginTop: '1%'
                    }}
                    columns={columns} 
                    dataSource={dataTable}  
                    scroll={{ y: adjustedTableHeigthScroll }}                                   
                    pagination={{
                        pageSize: 50,
                        showSizeChanger: false,                                      
                    }}
                  /> 
                : 
                  <>
                  </>
            }
          </Row>             
      </div>
    </>
  )
};

export default DepositExtraction;