import React, {
    createContext,
    useEffect,
    useState,
  } from 'react';
import { ResponseAccounts } from '../../Services/useAccounts';

  export interface DataType {
    id: string;
    operation_type_id: string;
    operation_type_name: string;
    credit: string,
    debit: string,
    origin_account_id: string;
    origin_account_descriptrion: string;
    origin_account_currency_name: string;
    origin_account_currency_sign: string;  
    origin_account_currency_id: string; 
    origin_balance: string;  
    destination_account_id: string;
    destination_account_descriptrion: string
    balance: string,
    destination_account_currency_name: string;
    destination_account_currency_sign: string;  
    destination_account_currency_id: string;  
    destination_balance: string;  
    customer_id: string;
    customer_first_name: string;  
    customer_last_name: string;
    user_id: string ;
    user_name: string;
    user_last_name: string;
    description: string;
    currency_sale: string;
    approved: string;
    is_deleting_operation: string;
    deleted: string,
    date: string,
  
    destination_checkout_name: string,
    destination_checkout_id: string,
     
    origin_checkout_name: string,
    origin_checkout_id: string
    credit_number: string,
    debit_number: string,
    destination_checkout_sign: string,
    origin_checkout_sign: string

    destination_checkout_currency_id: string,
    origin_checkout_currency_id: string,

    destination_account_customer_name: string,
    destination_account_customer_last_name: string,
    destination_account_customer_id: string,

    origin_account_customer_name: string,
    origin_account_customer_last_name: string,
    origin_account_customer_id: string,
  //  commission: string
  
  }

  class VoidContext implements MovementAccountContext {
    get dataTable(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setDataTable(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get mutate(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setMutate(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get showingAccount(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setShowingAccount(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get balance(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setBalance(): never {
      throw new Error('Cannot consume context outside of provider');
    }
  }

  interface MovementAccountContext {
    dataTable: DataType[], 
    setDataTable: Function,
    mutate: boolean 
    setMutate: Function,
    showingAccount:{
      user_id: number,
      user: string,
      sign: string
      sign_id: number,
    } | undefined, 
    balance: ResponseAccounts | undefined,
    setBalance: Function
    setShowingAccount: Function
  }

  interface propsWithChildren {
    children: React.ReactNode
  }
  
  export const MovementAccountContext = createContext<MovementAccountContext>(new VoidContext());
  export const MovementAccountContextProvider= ({ children }: propsWithChildren): JSX.Element => {

    const [dataTable, setDataTable] = useState<DataType[]>([])
    const [mutate, setMutate] = useState<boolean>(false)
    const [balance, setBalance] = useState<ResponseAccounts | undefined>()

    const [ showingAccount, setShowingAccount ] = useState<{
      user_id: number,
      user: string,
      sign: string
      sign_id: number
    } | undefined>()

 
    return (
      <MovementAccountContext.Provider value={{
        dataTable, 
        setDataTable,
        mutate, 
        setMutate,
        showingAccount, 
        setShowingAccount,
        balance, 
        setBalance

      }}>{children}</MovementAccountContext.Provider>
    );
  };



  