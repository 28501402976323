import { KeyedMutator } from "swr";
import { useAuthSWR } from './useAuthSWR';
import { END_POINT_CUSTOMER } from '../Axios/ENDPOINTS';

export interface AllClientsProps {
  id: number,
  first_name: string,
  last_name: string,
  email: string,
  phone_number: number
}

export const useGetAllClients = () => {
    
    const { data, error, mutate }:{
      data: AllClientsProps[], 
      error: any,
      mutate: KeyedMutator<any>
    } = useAuthSWR([ `${END_POINT_CUSTOMER}/get_all` ])   
  
    return {
      data,
      mutate,
      error
    };
};