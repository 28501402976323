import React, { useContext, useEffect, useState } from "react";
import './styles.scss';
import { Checkbox, CheckboxProps, DatePicker, Form, Input, Modal, Select } from "antd";
import { TransfersBetweenBoxesProps } from "./types";
import { Accounts, useGetAllAccount } from "../../../Services/useGetAllAccount";
import { useOperations } from "../../../Services/useOperations";
import moment from "moment";
import { ResponseAccounts, useAccounts } from "../../../Services/useAccounts";
import { VariablesContext } from "../../../Contexts/UserContext/VariablesContext";
import { UserContext } from "../../../Contexts/UserContext/UsersContext";
import { MovementAccountContext } from "../../../Contexts/UserContext/MovementAcountContext";
import { OperationsContext } from "../../../Contexts/UserContext/OperationsContext";

export interface FilterValues{
    value: string,
    id: string,
    key: number,
}

export interface ValuesForm {
    origin_account: FilterValues,
    destination_account: FilterValues,
    amount: number,
    approved: boolean,
    description: string
}

export const TransfersBetweenAccounts: React.FC<TransfersBetweenBoxesProps> =({
    form,
    showForms,
    setShowForms, 
    rowSelect,
    setRowSelect
})=>{

    const {data: account} = useGetAllAccount()
    const {createOperation, editOperation} = useOperations()
    const {getAccountsCurrency} = useAccounts()
    const {getAccountsCurrencyAndCustomer} = useAccounts()  
    const {userData} = useContext(UserContext)
    const {collapsedFilsters, setCollapsedFilters} = useContext(VariablesContext)
    const { Option } = Select;
    const mandatory = [
        {
            required: true,
            message:'Este campo es obligatorio'
        }
    ]
    const {
        setMutate,
        showingAccount, 
      } = useContext(MovementAccountContext)

    const {
        setMutate: mutateOperationsSections,     
    } = useContext(OperationsContext)
    
    const [loadingBtn, setLoadingBtn] = useState(false)
    const [listDestinationAccount, setListDestinationAccount] = useState<Accounts[]>([])
    const [loadingDestiantioinAccount, setLoadingDestinationAccount] = useState(false)    
    const [approved, setaproved] = useState(false)
    const [currency, setCurrency] = useState<{id: number, sign: string} | undefined>()
    const [balance, setBalance] = useState<ResponseAccounts>()
    const [spanError, setSpanError] = useState<string>() 

    useEffect(()=>{
        form.resetFields()
    },[])

   useEffect(() => {
       if(rowSelect && account){
            let origin_account = account.filter((x)=>x.id === Number(rowSelect.origin_account_id))[0]
            let destination_account = account.filter((x)=>x.id === Number(rowSelect.destination_account_id))[0]                                                      

            if(origin_account && destination_account ){
                form.setFieldsValue({

                    origin_account: {
                        label: origin_account.customer.first_name + ' ' + origin_account.customer.last_name,
                        value: origin_account.customer.first_name + ' ' + origin_account.customer.last_name,
                        key: origin_account.id,
                        currency: origin_account.currency
                    },
                    destination_account: {
                        label: destination_account.customer.first_name + ' ' + destination_account.customer.last_name,
                        value: destination_account.customer.first_name + ' ' + destination_account.customer.last_name,
                        key: destination_account.id,
                        currency: destination_account.currency
                    },
                    amount: rowSelect.debit_number? +rowSelect.debit_number : +rowSelect.credit_number,
                    description: rowSelect.description
                });  
            }            
        }else{
            form.resetFields(undefined) 
        }        
    }, [account]);

    const handleCancel =()=>{
        setShowForms({
            deposit_extraction: false,
            transferer: false,
            buy_and_sell: false,
        })
        if(setRowSelect){
            setRowSelect(undefined)
        }        
    }

    const handleSave = async (e: ValuesForm)=>{
        let operationData
        if(userData){
            operationData={
                type_id: 6, //correspondiente a Transferencia entre cuentas clientes, por eso va harcord
                origin_account_id: e.origin_account.key,
                destination_account_id: e.destination_account.key,
                debit: e.amount,
                user_id: userData.id,
                approved: approved,
                description: e.description 
            } 
        }                

        if(operationData){
            let newData
            if(rowSelect){
                newData = await editOperation(handleCancel, rowSelect.id, operationData)
            }else{
                newData = await createOperation(handleCancel, operationData)
            }
            if(newData){
                if ( showingAccount && ( showingAccount.user_id == newData.destination_account.customer.id ||showingAccount.user_id == newData.origin_account.customer.id ) &&
                (
                    showingAccount.sign_id == newData.origin_account?.currency.id|| 
                    showingAccount.sign_id == newData.destination_account?.currency.id
                )
                ){
                    setMutate(true)
                }
                mutateOperationsSections(true)
            }
           
            //checkAccountBalance()
            setCollapsedFilters(!collapsedFilsters)  
        }  
    }    

    const changeOriginAccount = async(e: FilterValues,  values: any) =>{
        form.setFieldsValue({ amount: undefined, destination_account: undefined });
        setSpanError(undefined)
        if(values && values.currency && values.customer){
            let currency = values.currency.id
            let customer = values.customer
            const balanceData = await getAccountsCurrencyAndCustomer(customer, currency) //consulto el balance de cliente
            setCurrency({id: values.currency.id, sign: values.currency.sign})
            if(balanceData && balanceData){
                setBalance( balanceData)
            }    
            setLoadingDestinationAccount(true)
            const accountsData = await getAccountsCurrency(values.currency.id, setLoadingDestinationAccount) //endpoint para cargar segundo selector según moneda de origen
            if(accountsData){
                let list = accountsData.filter((x)=> x.customer.id != customer  )
                setListDestinationAccount(list)
            }                    
        }          
    }

    const onChangeAmount = (e: any) =>{
        if(balance){ 
            if(e.target.value > balance.balance ){            
                let value = new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(balance.balance)
                setSpanError('El monto a transferir supera el monto de cuenta: ' + currency?.sign + ' ' + value  )  
            }
        }
    }  

    const onChangeapproved: CheckboxProps['onChange'] = async (e) => {
        setaproved(e.target.checked); 
    };


    return(
        <div>
            <Modal
                title= 'Transferencia entre cuentas cliente'
                open={showForms.transferer }
                onCancel={handleCancel}  
                confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar" 
                onOk={()=>form.submit()}
            >
                <Form
                    form={form}
                    id='form_deposit_extraction'
                    onFinish={handleSave}
                    layout="vertical"                    
                >    
                    <Form.Item
                        label='Cuenta de origen'
                        name='origin_account'
                        rules={mandatory}
                    >
                        <Select                           
                            labelInValue  
                            loading={!account }
                            placeholder={!account ? 'Cargando cuentas...' : 'Seleccione una cuenta de origen'}      
                            style={{minWidth: '200px'}}    
                            showSearch   
                            onChange={changeOriginAccount}                   
                        >
                            {account && account.map((item) => (
                                <Option 
                                    value={item.customer.first_name + ' ' + item.customer.last_name + ' ' + item.currency.name} 
                                    key={item.id} 
                                    currency = {item.currency} 
                                    customer ={item.customer.id}
                                    
                                >
                                    {item.customer.first_name + ' ' + item.customer.last_name + ' ' + item.currency.name}
                                </Option>
                            ))} 
                        </Select>
                    </Form.Item>      
                    <Form.Item
                        label='Cuenta de destino'
                        name='destination_account'
                        rules={mandatory}
                    >
                        <Select                           
                            labelInValue  
                            loading={loadingDestiantioinAccount}
                            placeholder={loadingDestiantioinAccount ? 'Cargando cuentas...' : 'Seleccione una cuenta destino'}      
                            style={{minWidth: '200px'}}    
                            showSearch                      
                        >
                            {listDestinationAccount && listDestinationAccount.map((item) => (
                                <Option value={item.customer.first_name + ' ' + item.customer.last_name + ' ' + item.currency.name} key={item.id} >
                                    {item.customer.first_name + ' ' + item.customer.last_name + ' ' + item.currency.name}
                                </Option>
                            ))} 
                        </Select>
                    </Form.Item>  
                    <Form.Item
                        label='Monto'
                        name='amount'
                        rules={mandatory}
                    >
                        <Input
                            placeholder="Monto"
                            type="number"
                            onChange={onChangeAmount}
                        />
                    </Form.Item>  
                    <Form.Item
                        name='approved'
                        valuePropName="checked"
                    >
                        <Checkbox
                            checked={approved}
                            onChange={onChangeapproved}
                        >
                            Aprobado
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                        label='Descripción'
                        name='description'
                    >
                        <Input
                            placeholder="Comentario"
                        />
                    </Form.Item>  
                    <span style={{color: 'red'}}>{spanError && spanError} </span>           
                </Form>
            </Modal>
        </div>
    )
}

export default TransfersBetweenAccounts;