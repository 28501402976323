import React, { useContext, useState } from 'react';
import './styles.scss'
import Menu from '../../Components/Menu';
import Navbar from '../../Components/Navbar';
import BodyContent from '../../Components/BodyContent';
import { StylesContext } from '../../Contexts/UserContext/StylesContext';
import Filters from '../../Components/Filters';
import { DashboardBox } from '../../Components/Filters-Boxs/DashboardBox';
import { MovementsBox } from '../../Components/Filters-Boxs/MovementsBox';
import { VariablesContext } from '../../Contexts/UserContext/VariablesContext';
import DepositExtractionForm from '../../Components/Forms/DepositExtractionForm';
import { useForm } from 'antd/es/form/Form';
import TransfersBetweenAccounts from '../../Components/Forms/TransfersBetweenAccounts';
import PucrchaseSaleCurrencyForm from '../../Components/Forms/PucrchaseSaleCurrencyForm';
import TransfersBetweenBoxes from '../../Components/Forms/TransfersBetweenBoxes';


export const Dashboard: React.FC =()=>{

  const {dashboard_body} = useContext(StylesContext)
  const {setShowForms, showForms} = useContext(VariablesContext)

  const [form_deposit_extraction] = useForm()
  const [form_transferers] = useForm()
  const [form_buy_and_sell] = useForm()


  const[menuBalances, setMenuBalances]= useState(false)
  const[menuForms, setMenuForms]= useState(false)


  return(
    <div className="dashboard_container">
      { showForms.deposit_extraction &&
        <DepositExtractionForm
          form={form_deposit_extraction}
          showForms={showForms}
          setShowForms={setShowForms}
        />
      }
      { showForms.transferer && 
        <TransfersBetweenAccounts
          form={form_transferers}
          showForms={showForms}
          setShowForms={setShowForms}
        />
      }    
      { showForms.buy_and_sell &&
        <PucrchaseSaleCurrencyForm
          form_buy_and_sell={form_buy_and_sell}
          showForms={showForms}
          setShowForms={setShowForms}
        />
      } 
      { showForms.transfers_between_cashs && 
        <TransfersBetweenBoxes
          form={form_transferers}
          showForms={ showForms.transfers_between_cashs}
          setShowForms={setShowForms}
        />
      }   
      <Filters 
        components={<DashboardBox/>} 
        height={0} 
        color={'rgba(255, 0, 0, 0.555)'} 
        detailsColor={'red'}
        tooltip='Saldos de Cajas'
        collapsedFilsters={menuBalances}
        setCollapsedFilters={setMenuBalances}
        marginTop={0}  
        widhtSections={600}      
      />
      <Filters 
        components={
          <MovementsBox 
            setShowForms={setShowForms}  
          />        
        }
        height={60}
        marginTop={60}
        tooltip='Formularios'
        collapsedFilsters={menuForms}
        setCollapsedFilters={setMenuForms}
      />
      
      <Navbar/>    
      <div className='dashboard_body'
        style={{
          height: `${dashboard_body}px`
        }}
      >
        <Menu/>
        <BodyContent/>
      </div>       
    </div>
  )
}