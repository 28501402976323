
import {
  END_POINT_OPERATION_GET_ALL, 
  END_POINT_OPERATION, 
  END_POINT_OPERATION_DEPOSIT_AND_EXTRACTIONS,
  END_POINT_OPERATION_CONFIRM
} from '../Axios/ENDPOINTS';
import { axiosBackEndInstance } from "../Axios";
import { useErrorHandling } from "../hooks/useErrorHandling";
import { notification } from "antd";
import { AxiosResponse } from 'axios';
import { useContext } from 'react';
import { BalancesContext } from '../Contexts/UserContext/BalancesContext';

interface Currency {
  id: number;
  name: string;
  sign: string;
}

interface Customer {
  id: number;
  created_at: string;
  updated_at: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
}

interface AccountType {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
}

interface Account {
  id: number;
  created_at: string;
  updated_at: string;
  description: string;
  balance: number;
  currency: Currency;
  customer: Customer;
  type: AccountType;
}

interface Checkout {
  id: number;
  created_at: string;
  updated_at: string;
  description: string;
  branch_id: number;
  currency: Currency;
  balance: number;
  name: string;
  future_balance: number
}

interface OperationType {
  id: number;
  name: string;
}

interface User {
  id: number;
  created_at: string;
  updated_at: string;
  email: string;
  first_name: string;
  last_name: string;
  role: string;
}

export interface Transaction {
  id: number;
  approved: boolean;
  created_at: string;
  updated_at: string;
  debit: number;
  credit: number;
  description: string;
  type: OperationType;
  origin_checkout: Checkout;
  destination_checkout: Checkout;
  destination_account_balance: number;
  destination_checkout_balance: number;
  origin_checkout_balance: number;
  origin_account_balance: number;
  origin_account: Account;
  destination_account: Account;
  customer: Customer;
  user: User;
  currency_sale: boolean | null;
  is_deleting_operation: boolean;
  deleted: boolean;
  date: string
}

export interface OperationTypeProps {
  type_id: number;
  date?: string;
  origin_checkout_id?: number;
  destination_checkout_id?: number;
  origin_account_id?: number;
  destination_account_id?: number;
  customer_id?: number;
  main_currency_id?: number;
  secondary_currency_id?: number;
  user_id?: number;
  credit?: number;
  debit?: number;
  currency_sale?: boolean;
  related_operation_id?: number;
  currency_quote_buy_price?: number;
  currency_quote_sell_price?: number;
  commission?: number;  
  description?: string;  
  approved?: boolean;
}


export const useOperations= () => {

    const {errorHandling} = useErrorHandling()
    
  const { setMutateBalance, mutateBalance } = useContext(BalancesContext) 
    //const {mutate} = useGetMovements()
     
    const getOperationAmount = async (      
      callback: Function,
      values:{
        customer_id: number | undefined,
        currency_id: number | undefined, 
        checkout_id: number | undefined,   
        start_date: string | undefined,
        end_date: string | undefined,
        operation_type: string | undefined
      }     
    ): Promise<Transaction[] | undefined> => {      
    try {

      var params = new URLSearchParams();
      values.currency_id && params.append("currency_id", values.currency_id.toString())
      values.customer_id && params.append("customer_id", values.customer_id.toString())
      values.checkout_id && params.append("checkout_id",  values.checkout_id.toString())
      values.start_date && params.append("start_date",  values.start_date.toString())
      values.end_date && params.append("end_date",  values.end_date.toString())
      values.operation_type && params.append("types_id",  values.operation_type.toString())

      const data = await axiosBackEndInstance.get(
          END_POINT_OPERATION_GET_ALL,
          { 
              params
          }
      )

      callback()
      if(data.data){
          return data.data
      }
    } catch (newError) {
      callback()
      const status =  await errorHandling(newError).request.status      
      console.log('status ' + status + ' ' + newError);      
    }
  };

 

  const createOperation = async (
    callback: Function,
    values: OperationTypeProps    
    ): Promise<Transaction | void> => {      
  try {
    const  response = await axiosBackEndInstance.post(
      END_POINT_OPERATION, values
    )
    const status =  errorHandling(response).request.status
    if(status === 200 && response){
      callback()
      notification.success({
        message: 'Éxito', 
        description:'Operación guardada con éxito', 
        placement: 'topRight'
      })
      setMutateBalance(!mutateBalance)
      return response.data
    }
    } catch (newError) {
      console.log(newError);
      callback()      
    }
  };

  const editOperation = async (
    callback: Function,
    operation_id: string,
    values: OperationTypeProps    
    ): Promise<Transaction | void> => {      
  try {
    const  response = await axiosBackEndInstance.put(
      `${END_POINT_OPERATION}${operation_id}`, values
    )
    const status =  errorHandling(response).request.status
    if(status === 200 && response){
      callback()
      notification.success({
        message: 'Éxito', 
        description:'Operación guardada con éxito', 
        placement: 'topRight'
      })
      setMutateBalance(!mutateBalance)
      return response.data
    }
    } catch (newError) {
      console.log(newError);
      callback()      
    }
  };

  const deleteOperation = async (
    callbackEliminate: Function,
    operation_id: number
    ): Promise<Transaction | void> => {      
  try {
    const  response = await axiosBackEndInstance.delete(
      `${END_POINT_OPERATION}${operation_id}`
    )
    const status =  errorHandling(response).request.status
    if(status === 200 && response){
      callbackEliminate()
      notification.success({
        message: 'Éxito', 
        description:'Operación eliminada con éxito', 
        placement: 'topRight'
      })
      return response.data
    }
    } catch (newError) {
      console.log(newError);
      callbackEliminate()   
      notification.error({
        message: 'Error', 
        description:'Hubo un error, comuníquese con el administrador', 
        placement: 'topRight'
      })   
    }
  };

  const getDepositAndExtractions = async (
    callback: Function,
    customer_id: number        
    ): Promise<Transaction[] | void> => {      
  try {
    const  data = await axiosBackEndInstance.get(
      `${END_POINT_OPERATION_DEPOSIT_AND_EXTRACTIONS}`
      //`${END_POINT_OPERATION_DEPOSIT_AND_EXTRACTIONS}/${customer_id}`
    )
    const status =  errorHandling(data).request.status
    if(status === 200){
      callback()      
      return data.data
    }
    } catch (newError) {
      console.log(newError);
      callback()      
    }
  };  

  const confirmOperation = async(
    operation_id: number,
    setLoadingApproved: Function
  ): Promise<number | undefined> =>{
    try{
      const data = await axiosBackEndInstance.put(
      `${END_POINT_OPERATION_CONFIRM}/${operation_id}`
      )
      if(data){
        setLoadingApproved(false)
        notification.success({
          message: 'Éxito', 
          description:'Operación aprobada con éxito', 
          placement: 'topRight'
        })
        return data.status
      }
    }catch(error: any){
      console.log(error)
      if(error?.response?.status == 402){
        setLoadingApproved(false)
        notification.warning({
          message: 'Info', 
          description:'La caja no tiene suficientes fondos', 
          placement: 'topRight'
        })
      }
    }
  }
 
  return {
    getOperationAmount,
    createOperation,
    getDepositAndExtractions,
    confirmOperation,
    deleteOperation,
    editOperation
  };
};
