import React, { useEffect, useState } from "react";
import './styles.scss';
import { Form, Input, Modal, Select } from "antd";
import { UserFormProps } from "./types";
import { useUsers } from "../../../Services/useUsers";

export interface FilterValues{
    value: string,
    label: string,
  }
export interface UserValuesForm {
    email: string,
    first_name: string,
    last_name: string,     
    password: string,
    role: FilterValues
}

export const UserForm: React.FC<UserFormProps> =({
    openForm,
    callback,
    rowSelect,
    form_user
})=>{
    
    const [loadingBtn, setLoadingBtn] = useState(false)
    const {createUser, editUser} = useUsers()
    const { Option } = Select

    const roleOptions =[
        {
            value: 'Administrador',
            label: 'Administrador'
        },
        {
            value: 'Operador',
            label: 'Operador'
        }
    ]

    useEffect(()=>{
        form_user.resetFields()
    },[])

    const handleCancel =()=>{
        callback()
        setLoadingBtn(false)
    }

    const handleSave = async (values: UserValuesForm)=>{
        setLoadingBtn(true)
        let newUserData = {
            email: values.email,
            first_name: values.first_name,
            last_name: values.last_name,     
            password: values.password,
            role: values.role.value
        }
        if(rowSelect){
            await editUser(handleCancel, rowSelect.id, newUserData)
        }else{
            await createUser(handleCancel, newUserData)
        }
    }    

    return(
        <div>
            <Modal
                title= {rowSelect ? 'Editar usuario' : 'Crear Usuario'}
                open={openForm}
                onCancel={handleCancel}  
                confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar" 
                onOk={()=>form_user.submit()}
            >
                <Form
                    form={form_user}
                    id='form_user'
                    onFinish={handleSave}
                    layout="vertical"                    
                >
                    <Form.Item
                        label='Usuario'
                        name='email'
                        initialValue={rowSelect?.email}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            },
                            {
                                type: 'email',
                                message: 'Por favor, ingrese un correo electrónico válido'
                            }
                        ]}
                    >
                        <Input
                            placeholder="Email"
                        />
                    </Form.Item>    
                    {!rowSelect && <Form.Item
                        label='Contraseña'
                        name='password'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <Input
                            placeholder="contraseña"
                        />
                    </Form.Item> }
                    <Form.Item
                        label='Nombre'
                        name='first_name'
                        initialValue={rowSelect?.first_name}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <Input
                            placeholder="Nombre"
                        />
                    </Form.Item>       
                    <Form.Item
                        label='Apellido'
                        name='last_name'
                        initialValue={rowSelect?.last_name}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <Input
                            placeholder="Apellido"
                        />
                    </Form.Item>  
                    {/* <Form.Item
                        label='Teléfono'
                        //name='phone'
                    >
                        <Input
                            placeholder="Teléfono"
                        />
                    </Form.Item>    */}
                    <Form.Item
                        label='Tipo de usuario'
                        name='role'
                        initialValue={{
                            value: rowSelect?.role,
                            label: rowSelect?.role,
                        }}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <Select
                            labelInValue
                        >
                            {roleOptions && roleOptions.map((item) => (
                                <Option value={item.value} >
                                {item.label}
                                </Option>
                            ))} 
                        </Select>
                    </Form.Item> 
                </Form>
            </Modal>
        </div>
    )
}

export default UserForm;