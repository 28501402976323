const END_POINT_AUTH_LOGIN = '/auth/login';
const END_POINT_AUTH_REGISTER = '/auth/register';
const END_POINT_ACCOUNT_PASSWORD = '/auth/change_password';
const END_POINT_USER_GET_ALL = '/user/get_all';
const END_POINT_USER = '/user';
const END_POINT_CURRENCY = '/currency/';
const END_POINT_CURRENCY_QUOTE= '/currency/quote'
const END_POINT_CURRENCY_QUOTE_BY_CURRENCY= '/currency/quote/by_currencies';
const END_POINT_CURRENCY_QUOTE_GET_ALL= '/currency/quote/get_all';
const END_POINT_CURRENCY_GET_ALL = '/currency/get_all';
const END_POINT_BRANCH_GET_ALL = '/branch/get_all';
const END_POINT_BRANCH = '/branch/';
const END_POINT_CASH_DESK_GET_ALL = '/checkout/get_all';
const END_POINT_CASH_DESK_GET_ALL_BANK = '/checkout/get_all_bank'; 
const END_POINT_CASH_GET_ALL_BY_CURRENCY = '/checkout/by_currency';
const END_POINT_CASH_DESK = '/checkout/';
const END_POINT_OPERATION_GET_ALL = '/operation/get_all';
const END_POINT_OPERATION = '/operation/';
const END_POINT_OPERATION_CONFIRM = '/operation/confirm';
const END_POINT_OPERATION_DEPOSIT_AND_EXTRACTIONS = '/operation/get_all_deposits_and_extractions'; 
const END_POINT_USER_ACCOUNT_GET_ALL = '/account/get_all'
const END_POINT_ACCOUNT_TYPE_GET_ALL = '/account/type/get_all'
const END_POINT_ACCOUNT_BY_CURRENCY = '/account/by_currency'
const END_POINT_ACCOUNT_BY_CUSTOMER_AND_CURRENCY = '/account/by_customer_and_currency'
const END_POINT_ACCOUNT_TOKEN = '/account/token/';
const END_POINT_USER_PROFILE ='/user/profile';
const END_POINT_CUSTOMER = '/customer';

export {
    END_POINT_AUTH_LOGIN,
    END_POINT_USER_GET_ALL,
    END_POINT_USER,  
    END_POINT_CURRENCY,
    END_POINT_CURRENCY_GET_ALL,
    END_POINT_BRANCH_GET_ALL,
    END_POINT_BRANCH,
    END_POINT_CASH_DESK_GET_ALL,
    END_POINT_CASH_DESK,
    END_POINT_CASH_GET_ALL_BY_CURRENCY,    
    END_POINT_OPERATION_GET_ALL,
    END_POINT_OPERATION,
    END_POINT_OPERATION_DEPOSIT_AND_EXTRACTIONS,
    END_POINT_ACCOUNT_TYPE_GET_ALL,
    END_POINT_ACCOUNT_BY_CURRENCY,
    END_POINT_ACCOUNT_TOKEN,  
    END_POINT_USER_PROFILE,
    END_POINT_ACCOUNT_BY_CUSTOMER_AND_CURRENCY,
    END_POINT_CUSTOMER,
    END_POINT_USER_ACCOUNT_GET_ALL,  
    END_POINT_CASH_DESK_GET_ALL_BANK,
    END_POINT_CURRENCY_QUOTE_BY_CURRENCY,
    END_POINT_CURRENCY_QUOTE_GET_ALL,
    END_POINT_CURRENCY_QUOTE,
    END_POINT_OPERATION_CONFIRM,
    END_POINT_AUTH_REGISTER,
    END_POINT_ACCOUNT_PASSWORD  
}